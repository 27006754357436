<template>
  <div class="flex-center">
    <div class="w1200 price-cont">
      <div class="filter-line">
        <div class="filter-label">影响因子</div>
        <el-checkbox-group v-model="checkList" class="checkbox">
          <el-checkbox label="往期价格"></el-checkbox>
          <el-checkbox label="天气"></el-checkbox>
          <el-checkbox label="政策"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="filter-line">
        <div class="filter-label">时间范围</div>
        <el-date-picker
            v-model="value1"
            class="date-picker"
            end-placeholder="结束日期"
            size="mini"
            start-placeholder="开始日期"
            type="daterange">
        </el-date-picker>
      </div>
      <div class="chart-title">生猪价格预测</div>
      <div ref="priceChart" class="chart-box"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "price",
  data() {
    return {
      checkList: [],
      value1: '',
      detail: {}
    }
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      let myChart = echarts.init(this.$refs.priceChart);
      let option;
      option = {
        color: ['#4BC2FF', '#FFCC39', '#FF7552'],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 109,
          right: 'left',
          itemGap: 40,
        },
        grid: {
          left: '0',
          right: '4%',
          bottom: '0',
          top: '70',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          splitLine: {
            show: true,
            lineStyle: {
              color: '#E7E8EB',
              type: 'dashed' // 设置为虚线
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#E7E8EB',
              type: 'dashed' // 设置为虚线
            }

          }
        },
        series: [
          {
            name: '历史价格',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 210],
            symbolSize: 5,
            symbol: 'circle',
          },
          {
            name: '预测价格（综合）',
            type: 'line',
            data: [220, 182, 191, 234, 290, 330, 310],
            symbolSize: 5,
            symbol: 'circle',
          },
          {
            name: '预测价格（历史猪肉价格、天气）',
            type: 'line',
            data: [150, 232, 201, 154, 190, 330, 410],
            symbolSize: 5,
            symbol: 'circle',
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>


