<template>
  <div class="page-height">
    <div class="flex-center price-top">
      <div class="w1200">
        <div class="flex-between price-top-title">
          <div class="page-title">{{ detail.modelname }}</div>
          <div class="price-top-title-right" v-if="detail.attFileInfoEntityList && detail.attFileInfoEntityList.length" @click="downloadFile">
            产业分析
            <div class="iconfont icon-yunduanxiazai"></div>
          </div>
        </div>
        <div class="box-info">{{ detail.modeldesc }}</div>
        <div class="price-option" v-if="detail.merit">
          <div class="price-option-item" v-for="item in detail.merit.split(',')">
            <div class="zan"></div>
            {{ item }}
          </div>
        </div>
      </div>
    </div>

<!--      <Price />-->
<!--      <Points />-->
    <div class="flex-center">
      <div class="w1200 price-cont points-cont">
        <el-descriptions style="width: 100%" title="模型信息" :column="3" border labelClassName="description-label" contentClassName="description-cont">
          <el-descriptions-item>
            <template slot="label">模型类名</template>
            {{ detail.modelname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">最新运行的获取时间</template>
            {{ detail.latesttime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">最新运行状态</template><el-tag :type="detail.deleteStatus ? 'danger' : 'success'">{{ detail.deleteStatus ? '停用':'正常' }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item >
            <template slot="label">模型预测URL</template>
            {{ detail.predicturl }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">批量预测URL</template>
            {{ detail.batchPredicturl }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">最新运行信息</template>
            {{ detail.latestinfo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">模型数据列名</template><el-tag style="margin-right: 15px" v-for="item in detail.columns">{{ item }}</el-tag>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>

  </div>
</template>

<script>
import {Downloadmodel, LmpModelDetail} from "@/assets/js/api";

import Price from './components/price.vue'
import Points from './components/points.vue'

export default {
  name: "price",
  components:{Price,Points},
  data() {
    return {
      checkList: [],
      value1: '',
      detail:{}
    }
  },
  mounted(option) {
    console.log(this.$route.query.name)
    LmpModelDetail({modelname:this.$route.query.name}).then(res=>{
      this.detail = res
      console.log(res)
    })
  },
  methods: {
    downloadFile(){
      window.open(URLS.baseApi +'/api/inner/service/downloadmodel?ids=' + this.detail.attFileInfoEntityList[0].id)
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/superMarket/price.css";
</style>

<style>
@import "../../assets/css/form.css";
</style>


