<template>
  <div class="flex-center">
    <div class="w1200 price-cont points-cont flex-between">
      <div class="points-left" style="display: none">
        <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="fileList">
          <div class="upload-item">
            <div class="upload-item-btn"><i class="iconfont icon-shangchuan"></i>上传图片或视频</div>
            <div class="upload-item-tips">图片支持jpg.png.jpeg；视频支持mp4.avi.mov</div>
          </div>
        </el-upload>
      </div>
      <div class="points-left-box">
        <img src="../../../assets/img/superMarket/pig.png" alt="">
      </div>
      <div class="points-right flex-between">
        <div class="points-in">
          <div class="points-label">数量(头)</div>
          <input type="number" class="points-input" min="0">
          <div class="points-num-toast">
            <div class="points-num-toast-title flex-between">
              <div>识别数量是否准确？</div>
              <div class="iconfont icon-close-l"></div>
            </div>
            <div class="points-num-toast-info">请用鼠标点击在左侧标注正确生猪数量</div>
            <div class="points-num-toast-btn">
              <div>完成</div>
              <!--                <div>否</div>-->
            </div>
          </div>
        </div>
        <div class="flex-between">
          <div class="points-btn grey-btn"><i class="iconfont icon-xingzhuang"></i>替换图片</div>
          <div class="points-btn blue-btn" @click="uploadToast = true"><i class="iconfont icon-zhongxinxuanqu-"></i>重新识别</div>
        </div>
      </div>
    </div>
    <el-dialog
        custom-class="toast-dialog"
        :show-close="false"
        :visible.sync="uploadToast"
        width="564px">

      <div class="toast-dialog-info">视频识别技术正在完善中，请先上传图片</div>
      <div class="toast-dialog-btn" @click="uploadToast = false">确定</div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "points",
  data(){
    return{
      uploadToast:false
    }
  }
}
</script>

